var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-form", [
        _c(
          "div",
          { staticClass: "section" },
          [
            _c(
              "AuthorityComponent",
              {
                staticClass: "top-btn",
                attrs: {
                  ComponentName: "el-button",
                  permission: ["button.edit"],
                  type: "primary",
                  size: "mini",
                },
                on: { click: _vm.save },
              },
              [_vm._v("保存")]
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("启动页广告数据源控制:"),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "APP:", "label-width": "40px" } },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.startUpSource,
                                  callback: function ($$v) {
                                    _vm.startUpSource = $$v
                                  },
                                  expression: "startUpSource",
                                },
                              },
                              [
                                _c(
                                  "el-row",
                                  { staticClass: "radio-row" },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("仅内部数据")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("全部数据")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "60px" },
                        attrs: { label: "", "label-width": "40px" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.startUpDownload,
                                  callback: function ($$v) {
                                    _vm.startUpDownload = $$v
                                  },
                                  expression: "startUpDownload",
                                },
                              },
                              [
                                _c(
                                  "el-row",
                                  { staticClass: "radio-row" },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("禁止下载")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("允许下载")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("首页轮播广告数据源控制:"),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "APP:", "label-width": "40px" } },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.wheelSource,
                                  callback: function ($$v) {
                                    _vm.wheelSource = $$v
                                  },
                                  expression: "wheelSource",
                                },
                              },
                              [
                                _c(
                                  "el-row",
                                  { staticClass: "radio-row" },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("仅内部数据")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("全部数据")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-left": "60px" },
                        attrs: { label: "", "label-width": "40px" },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.wheelDownload,
                                  callback: function ($$v) {
                                    _vm.wheelDownload = $$v
                                  },
                                  expression: "wheelDownload",
                                },
                              },
                              [
                                _c(
                                  "el-row",
                                  { staticClass: "radio-row" },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 1 } },
                                          [_vm._v("禁止下载")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("允许下载")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }