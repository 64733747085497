<template>
  <div>
    <el-form>
      <div class="section">
        <AuthorityComponent
          ComponentName="el-button"
          :permission="['button.edit']"
          type="primary"
          size="mini"
          class="top-btn"
          @click="save"
          >保存</AuthorityComponent
        >
        <el-row>
          <el-col :span="12">
            <div class="title">启动页广告数据源控制:</div>
            <el-form-item label="APP:" label-width="40px">
              <div>
                <el-radio-group v-model="startUpSource">
                  <el-row class="radio-row">
                    <el-col :span="12">
                      <el-radio :label="1">仅内部数据</el-radio>
                    </el-col>
                    <el-col :span="12">
                      <el-radio :label="0">全部数据</el-radio>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item label="" label-width="40px" style="margin-left: 60px">
              <div>
                <el-radio-group v-model="startUpDownload">
                  <el-row class="radio-row">
                    <el-col :span="12">
                      <el-radio :label="1">禁止下载</el-radio>
                    </el-col>
                    <el-col :span="12">
                      <el-radio :label="0">允许下载</el-radio>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div class="title">首页轮播广告数据源控制:</div>
            <el-form-item label="APP:" label-width="40px">
              <div>
                <el-radio-group v-model="wheelSource">
                  <el-row class="radio-row">
                    <el-col :span="12">
                      <el-radio :label="1">仅内部数据</el-radio>
                    </el-col>
                    <el-col :span="12">
                      <el-radio :label="0">全部数据</el-radio>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item label="" label-width="40px" style="margin-left: 60px">
              <div>
                <el-radio-group v-model="wheelDownload">
                  <el-row class="radio-row">
                    <el-col :span="12">
                      <el-radio :label="1">禁止下载</el-radio>
                    </el-col>
                    <el-col :span="12">
                      <el-radio :label="0">允许下载</el-radio>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      startUpDownload: "",
      startUpSource: "",
      wheelDownload: "",
      wheelSource: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      const url = "/acb/2.0/advertConfig/list";
      const opt = {
        url,
        method: "get",
        data: {},
        success: (res) => {
          const startUpPage = res.value[0];
          const WheelPlanting = res.value[1];
          this.startUpDownload = startUpPage.download;
          this.startUpSource = startUpPage.source;
          this.wheelDownload = WheelPlanting.download;
          this.wheelSource = WheelPlanting.source;
        },
      };
      this.$request(opt);
    },
    save() {
      const tempData = [
        {
          id: 1,
          name: "app首页轮播",
          download: parseInt(this.wheelDownload),
          source: parseInt(this.wheelSource),
        },
        {
          id: 2,
          name: "app启动页",
          download: parseInt(this.startUpDownload),
          source: parseInt(this.startUpSource),
        },
      ];
      const opt = {
        url: "/acb/2.0/advertConfig/update",
        method: "post",
        config: {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        },
        data: tempData,
        success: (res) => {
          this.$alert("操作成功", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        },
      };
      this.$request(opt);
    },
  },
};
</script>

<style lang="stylus" scoped>
.section
  background-color #fff
  padding 30px 50px
  margin-bottom 20px
  position relative
  .top-btn
    position absolute
    top 20px
    right 20px
  .title
    font-weight bold
    margin-bottom: 20px
.radio-row
  width 220px
  line-height 3em
</style>
